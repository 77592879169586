<template>
  <div>
    <v-expansion-panels v-model="panel" flat multiple class="ma-4 ms-1">
      <v-expansion-panel :class="`${outlined ? 'outlined' : ''}`">
        <v-progress-linear
          v-if="showLoading"
          indeterminate
          color="primary"
        ></v-progress-linear>
        <v-expansion-panel-header
          class="grey--text text--darken-2 font-weight-medium text-body-1"
        >
          <v-row align-content="center">
            <v-col class="d-block" cols="9">
              <div class="mx-0 pt-0 px-0">
                {{ $t("common.insertUserIntoCalendar") }}
              </div>
              <small class="mx-0 pt-0 px-0">{{
                $t("common.userCalendars")
              }}</small>
            </v-col>
          </v-row>
        </v-expansion-panel-header>

        <v-expansion-panel-content class="pb-0" style="position: relative">
          <v-card-text class="mx-0 pt-0 px-0">
            {{ $t("common.addUserCalendarsDescription") }}
          </v-card-text>

          <v-simple-table class="mb-4">
            <template v-slot:default>
              <tbody>
                <tr>
                  <td v-for="(item, i) in headers" :key="i">{{ item }}</td>
                </tr>
                <tr v-for="(item, i) in userCalendars" :key="i">
                  <td>{{ item.calendar.summary }}</td>
                  <td>{{ item.calendar.description }}</td>

                  <td align="end" v-if="!item.calendar.primary">
                    <v-btn
                      text
                      small
                      class="ma-2"
                      outlined
                      color="accent"
                      dark
                      @click="
                        (showDialogDeleteCalendar = true),
                          (CalendarSelected = item)
                      "
                      >{{ $t("action.delete") }}</v-btn
                    >

                    <v-dialog
                      :retain-focus="false"
                      v-model="showDialogDeleteCalendar"
                      width="500"
                      @keydown.esc="$emit(`close`)"
                      @click:outside="$emit(`close`)"
                      content-class="elevation-0"
                      overlay-opacity="0.1"
                    >
                      <v-card>
                        <h2 class="title py-3 px-6">Confirmar exclusão</h2>
                        <v-card-text
                          >Você realmente deseja remover o usuário da agenda
                          <strong>{{
                            CalendarSelected.calendar?.summary ||
                            CalendarSelected.calendar?.id
                          }}</strong
                          >?</v-card-text
                        >
                        <v-divider></v-divider>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="secondary"
                            text
                            @click="reset()"
                            :loading="showLoading"
                            >Cancelar</v-btn
                          >
                          <v-btn
                            color="#311F50"
                            text
                            @click="removeCalendar()"
                            :loading="showLoading"
                            >Confirmar</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </td>
                  <td v-else align="end">
                    <v-btn text small disabled class="ma-2">{{
                      $t("action.delete")
                    }}</v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-tooltip :disabled="userHasGmail" bottom>
            <template v-slot:activator="{ on, attrs }">
              <div style="width: fit-content" v-bind="attrs" v-on="on">
                <v-btn
                  :color="`${isOnboardingPage ? 'primarySuite' : 'accent'}`"
                  @click="showDialogAddCalendar = true"
                  :disabled="showLoading || !userHasGmail || !hasCalendars"
                  :loading="showLoading"
                  class="mt-0 elevation-0 text-none font-weight-medium text-body-2 white--text"
                >
                  {{ $t("common.addCalendar") }}
                </v-btn>
              </div>
            </template>
            <span v-if="!userHasGmail">
              {{ $t("common.inactiveEmailService") }}
            </span>
            <span v-if="!hasCalendars && userHasGmail">
              {{ $t("common.calendarsNotFound") }}
            </span>
          </v-tooltip>
          <InsertUsersCalendars
            :show="showDialogAddCalendar"
            :users="[user]"
            @close="showDialogAddCalendar = false"
            @getUserCalendars="getCalendarsFromUser()"
            @saveLog="saveAddCalendarsLog()"
          />
          <!-- OVERLAY FOR SCOPES MISSING  -->
          <OverlayScopes
            :overlay="overlayScopes"
            :functionality="$t('common.insertUserIntoCalendar')"
            dense
            :loading="showLoading"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { setFirebaseEvent } from "@/helpers/services/registerAnalytics.js";
import {
  errorMessages,
  successMessages,
} from "@/helpers/variables/snackbarMessages";
import { mapActions, mapGetters, mapMutations } from "vuex";
import OverlayScopes from "../general/OverlayScopes.vue";
import InsertUsersCalendars from "../sign/users/InsertUsersCalendars.vue";

export default {
  name: "UserCalendars",
  components: {
    InsertUsersCalendars,
    OverlayScopes,
  },
  props: {
    outlined: { type: Boolean, default: false },
    user: { type: Object, default: () => {} },
    isPanelOpened: { type: Boolean, default: false },
  },
  data() {
    return {
      panel: [this.isPanelOpened ? 0 : 1],
      showSnackbar: false,
      snackbarType: "success",
      snackbarText: "",
      showLoading: false,

      items: [],
      showDialogAddCalendar: false,
      showDialogDeleteCalendar: null,

      userCalendars: {},
      CalendarSelected: "",
      overlayScopes: false,
    };
  },

  computed: {
    ...mapGetters(["allCalendars", "mainDomain"]),

    userHasGmail() {
      return this.user.is_gmail_enabled;
    },

    headers() {
      return [this.$t("common.schedule"), this.$t("common.description")];
    },

    hasCalendars() {
      const keys = Object.keys(this.userCalendars);
      return keys.length;
    },

    isOnboardingPage() {
      if (this.$route.name === "Onboarding") return true;
      return false;
    },
  },

  methods: {
    ...mapActions([
      "getCalendars",
      "getUserCalendars",
      "removeCalendarFromUser",
    ]),
    ...mapMutations(["setSnackBar", "setUserById"]),

    setFirebaseEvent,

    saveAnalytics(log) {
      if (this.mainDomain) {
        this.setFirebaseEvent(this.mainDomain, log.event, log.click);
      }
    },

    saveAddCalendarsLog() {
      const log = {
        event: this.isOnboardingPage
          ? "onboarding_add_user_on_calendars"
          : "add_user_on_calendars",
        click: "Botão de adicionar agendas para o usuário",
      };
      this.saveAnalytics(log);
    },

    removeCalendar() {
      this.showLoading = true;
      const key = this.user.key;
      let payload = {
        data: { calendar_id: this.CalendarSelected.calendar.id },
        key: key,
      };

      this.removeCalendarFromUser(payload)
        .then(() => {
          this.setSnackBar({
            show: true,
            message: successMessages.delete_24hours,
          });
          this.getCalendarsFromUser();
        })
        .catch((err) => {
          console.log("removeGroup()", err);
          const code = err.response.data.code;
          if (code === "INVALID_GOOGLE_ACCESS_TOKEN") {
            this.overlayScopes = true;
            return;
          }
          this.setSnackBar({
            show: true,
            message: errorMessages[code] || errorMessages.unknown,
            color: "error",
          });
        })
        .finally(() => {
          const log = {
            event: this.isOnboardingPage
              ? "onboarding_remove_user_from_calendar"
              : "remove_user_from_calendar",
            click: "Botão de excluir agenda da lista de agendas do usuário",
          };
          this.saveAnalytics(log);
          this.reset();
        });
    },

    reset() {
      this.showDialogAddCalendar = false;
      this.showDialogDeleteCalendar = false;
      this.CalendarSelected = "";
      this.showLoading = false;
      this.addSendAs = false;
    },

    getCalendarsFromUser() {
      this.showLoading = true;
      this.getUserCalendars(this.user.key)
        .then(({ data }) => {
          this.userCalendars = data;
        })
        .catch((error) => {
          console.error("Vuex: getUserCalendars(): ", error);
          if (error.response?.data?.code === "INVALID_GOOGLE_ACCESS_TOKEN") {
            this.overlayScopes = true;
            return;
          }
        })
        .finally(() => {
          this.showLoading = false;
        });
    },
  },

  watch: {
    user() {
      if (this.user) {
        this.setUserById(this.user);
      }
    },

    panel(_, newV) {
      if (this.userHasGmail && newV.length === 1 && !this.hasCalendars) {
        this.getCalendarsFromUser();
      }
    },
  },

  async beforeMount() {
    if (this.isPanelOpened && this.userHasGmail) {
      this.getCalendarsFromUser();
    }
  },
};
</script>
