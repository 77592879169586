<template>
  <!-- This component need to be inside a element with style="position: relative" -->
  <v-overlay
    :absolute="true"
    color="primary"
    :value="overlay"
    opacity="0.8"
    class="pa-5"
  >
    <div class="d-flex flex-column align-center justify-center">
      <v-card-title v-if="title"> {{ title }} </v-card-title>
      <v-card-text
        :class="`font-weight-medium text-center ${dense ? '' : 'pb-6 mb-6'}`"
      >
        {{ $t("scopesAlert.alert", { functionality: functionality }) }}
        <span v-if="isAdmin">
          {{ $t("scopesAlert.requestToAcceptScopes") }}
        </span>
        <span v-else>
          {{ $t("scopesAlert.requestAdminToAcceptScopes") }}
        </span>
      </v-card-text>
      <v-btn
        v-if="isAdmin"
        max-width="200"
        :block="false"
        color="accent"
        class="text-none font-weight-medium text-body-2 white--text"
        elevation="0"
        :href="MARKETPLACE_APP_URL"
        target="_blank"
        :loading="loading"
        :disabled="loading"
      >
        {{ $t("action.acceptScopes") }}

        <v-icon right>mdi-open-in-new</v-icon>
      </v-btn>
    </div>
  </v-overlay>
</template>

<script>
import { MARKETPLACE_APP_URL } from "@/helpers/variables/scopes";
import { mapGetters } from "vuex";
export default {
  name: "OverlayScopes",
  props: {
    overlay: { type: Boolean, default: false },
    functionality: { type: String, required: true },
    dense: { type: Boolean, default: false },
    title: { type: String },
    loading: { type: Boolean, default: false },
  },
  data() {
    return {
      MARKETPLACE_APP_URL,
    };
  },
  computed: {
    ...mapGetters(["isAdmin"]),
  },
};
</script>

<style></style>
